import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { LocationMarkerIcon, XIcon } from "@heroicons/react/outline";
import { saveAs } from "file-saver";

export default function ImageModal({ open, setOpen }) {
  const downloadImage = () => {
    saveAs(
      "https://cdn.pmlsound.com/paradigm/NHS/canadian-dealers-gta-national-home-show-artwork-ver2-24in_x_36in_page-00011.jpg",
      "southern-ontario-dealers-map.jpg"
    ); // Put your image URL here.
  };
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <Dialog.Backdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-[70rem] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>

                <XIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-gray-200 sm:mx-0 sm:size-10">
                <LocationMarkerIcon className="h-6 w-6 text-gray-900" />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold text-gray-900 mt-2"
                >
                  Map of Paradigm/Anthem dealers in Southern Ontario
                </Dialog.Title>
                <div className="mt-2">
                  <img
                    src="https://cdn.pmlsound.com/paradigm/NHS/canadian-dealers-gta-national-home-show-artwork-ver2-24in_x_36in_page-00011.jpg"
                    alt="map"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={downloadImage}
                className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
              >
                Download
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
