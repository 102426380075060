import { LocationMarkerIcon, MapIcon } from "@heroicons/react/outline";
import React from "react";
import ImageModal from "../components/ImageModal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Alert from "../nhsSurvey/NhsSurvey/components/Alert";
import axios from "axios";
import DocumentTitle from "../components/DocumentTitle";

const Nhs2024 = () => {
  const [open, setOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <DocumentTitle title={"Paradigm - NHS 2025"} />
      <ImageModal open={open} setOpen={setOpen} />
      <section className="flex items-center justify-center bg-white">
        <header className="bg-transparent">
          <nav
            aria-label="Global"
            className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 lg:px-8"
          >
            <div className="flex ">
              <div href="#" className="-m-1.5 p-1.5 ml-6 md:ml-0">
                <span className="sr-only">Your Company</span>
                <img
                  src="https://cdn.pmlsound.com/paradigm/NHS/paradigm-logo-black.svg"
                  alt="logo"
                  className="w-40"
                />
              </div>
            </div>
            <div className="hidden lg:flex lg:gap-x-12 text-white">
              <p>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit ipsum quia dolor sit amet,
                consectetur
              </p>
            </div>
            <div className="ml-20 md:ml-0 flex items-center justify-end gap-x-6 pt-2 pb-2 md:pb-0">
              <div className="border-r-2 border-[#68bd44]">
                <img
                  src="https://cdn.pmlsound.com/paradigm/NHS/nhs-pngs.png"
                  alt="nhs"
                  className="w-24"
                />
              </div>
              <div className="">
                <img
                  src="https://cdn.pmlsound.com/paradigm/NHS/remax-pngs.png"
                  alt="remax"
                  className="w-16 mb-2"
                />
              </div>
              <div className="border-l-2 border-[#68bd44] pl-2 font-bold text-[10px] md:text-base">
                <span>MARCH 7-16</span>
                <br />
                <span>ENERCARE CENTER</span>
                <br />
                <span>EXHIBITION PLACE</span>
              </div>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Open main menu</span>
              </button>
            </div>
          </nav>
          {/* <div className="lg:hidden">
            <div className="fixed inset-0 z-10" />
            <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center gap-x-6">
             
                  <img
                  src="https://cdn.pmlsound.com/paradigm/NHS/paradigm-logo-black.svg"
                  alt="logo"
                  className="w-40"
                />
             
                
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                   
                    <p>
                      Neque porro quisquam est qui dolorem ipsum quia dolor sit
                      amet, consectetur, adipisci velit..
                    </p>
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </header>
      </section>

      <section className="bg-[url('https://cdn.pmlsound.com/paradigm/NHS/nhs-discount-page-background.jpg')] bg-cover bg-center bg-no-repeat">
        <div className="py-24 sm:py-16">
          <div className="mx-auto max-w-7xl px-6 lg:max-w-7xl lg:px-8">
            <div className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg lg:rounded-[2rem] py-6 px-8 md:px-0">
              <h2 className="text-center text-lg/7 font-semibold text-gray-950">
                Thank You for Visiting Us at the National Home Show!
              </h2>
              <p className="mx-auto mt-2 max-w-[70rem] text-balance text-center text-2xl font-semibold tracking-tight text-gray-800 sm:text-3xl">
                We appreciate you stopping by our booth. As a special thank you,
                we’re offering 20% off the Garden Oasis Essentials Outdoor
                System, Stylus Series, and Rock Monitor SM.
              </p>
            </div>
            <div className="mt-10 grid gap-4 sm:mt-6 lg:grid-cols-3 lg:grid-rows-2">
              <div className="relative lg:row-span-2 max-lg:row-start-1 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg lg:rounded-l-[2rem] overflow-hidden">
                <div className="absolute inset-0 rounded-lg lg:rounded-l-[2rem] border border-white/20"></div>

                <div className="relative flex h-full flex-col overflow-hidden rounded-lg lg:rounded-l-[2rem]">
                  <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                    <p className="mt-2 text-lg font-bold tracking-tight text-gray-950 max-lg:text-center">
                      Exclusive Offer – 20% Off
                    </p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-800 max-lg:text-center font-medium">
                      Promo Period: March 7 – March 21, 2025
                      <br />
                      Use promo code <b>NHS20</b> to claim your discount at
                      participating dealers.
                    </p>
                  </div>

                  <div className="-mt-6 relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                    <div className="absolute inset-x-10 bottom-[-24px] top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                      <img
                        className="size-full object-cover object-top"
                        src="https://cdn.pmlsound.com/paradigm/NHS/bento-03-mobile-friendly-01.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg overflow-hidden">
                <div className="absolute inset-0 rounded-lg border border-white/20"></div>

                <div className="relative flex h-full flex-col overflow-hidden rounded-lg lg:rounded-l-[2rem]">
                  <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                    <p className="mt-2 text-lg font-bold tracking-tight text-gray-950 max-lg:text-center">
                      Find a Dealer Near You!
                    </p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-800 max-lg:text-center font-medium">
                      We’ve included a map of our participating dealers, where
                      you can experience our products and redeem your promo
                      code.
                    </p>
                  </div>

                  <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                    <button
                      type="button"
                      className="cursor-pointer inline-flex items-center gap-x-1.5 rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={() => setOpen(!open)}
                    >
                      <LocationMarkerIcon
                        aria-hidden="true"
                        className="-ml-0.5 size-5"
                      />
                      Open the Map
                    </button>
                  </div>
                </div>
              </div>

              <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2 bg-white  bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg overflow-hidden">
                <div className="absolute inset-0 rounded-lg border border-white/20"></div>
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                  <div className="px-8 pt-4 sm:px-10 sm:pt-4">
                    <p className="mt-2 text-lg font-bold tracking-tight text-gray-900 max-lg:text-center">
                      Stay Connected for More Exclusive Offers!
                    </p>
                  </div>
                  <div className="px-10 [container-type:inline-size] max-lg:py-6 lg:pb-2">
                    {showAlert ? <Alert message={alertMessage} /> : null}

                    {success ? (
                      <div className="">
                        <p className="mt-20  text-center text-lg font-semibold text-green-700">
                          Thank you for signing up!
                        </p>
                      </div>
                    ) : (
                      <Formik
                        initialValues={{
                          name: "",
                          email: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Name is required"),
                          email: Yup.string()
                            .email("Invalid email address")
                            .required("Email is required"),
                        })}
                        onSubmit={(values) => {
                          setLoading(true);
                          axios({
                            method: "post",
                            url: "https://apps.paradigm.com/api/public/nhs2024",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            data: values,
                          })
                            .then((res) => {
                              // console.log(res.data);
                              if (res.data.status === "success") {
                                setSuccess(true);
                                setShowAlert(false);
                                setLoading(false);
                                // actions.setSubmitting(false);
                              } else if (res.data.status === "field_error") {
                                // setActiveStep(activeStep);
                                setShowAlert(true);
                                setLoading(false);
                                setAlertMessage([
                                  {
                                    path: "temp",
                                    msg: "Something went wrong! Check the form again.",
                                  },
                                ]);
                              }
                            })
                            .catch((err) => {
                              setShowAlert(true);
                              setLoading(false);
                              setAlertMessage(err?.response?.data?.errors);
                            });
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <div className="mt-3">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-800"
                              >
                                Name*
                              </label>
                              <div className="mt-2">
                                <Field
                                  name="name"
                                  type="text"
                                  placeholder="Enter Your Name"
                                  className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 placeholder:text-gray-400 focus:outline-2 focus:outline-blue-600 sm:text-sm ${
                                    errors.name && touched.name
                                      ? "border-red-500 outline-red-500"
                                      : "border-gray-900 outline-gray-900"
                                  }`}
                                />
                              </div>

                              <ErrorMessage
                                name="name"
                                component="div"
                                className="mt-1 text-sm text-red-600"
                              />
                            </div>

                            <div className="mt-3">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-800"
                              >
                                Email Address*
                              </label>
                              <div className="mt-2">
                                <Field
                                  name="email"
                                  type="email"
                                  placeholder="Enter Your Email"
                                  className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 placeholder:text-gray-400 focus:outline-2 focus:outline-blue-600 sm:text-sm ${
                                    errors.email && touched.email
                                      ? "border-red-500 outline-red-500"
                                      : "border-gray-900 outline-gray-900"
                                  }`}
                                />
                              </div>

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="mt-1 text-sm text-red-600"
                              />
                            </div>

                            <div className="py-6">
                              <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue-600"
                              >
                                {loading ? (
                                  <span>
                                    <svg
                                      aria-hidden="true"
                                      role="status"
                                      class="inline w-4 h-4 me-3 mt-0.5 text-white animate-spin"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                    Processing...
                                  </span>
                                ) : (
                                  "Sign Up for Updates"
                                )}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
              </div>

              <div className="relative lg:row-span-2 bg-white  bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg lg:rounded-r-[2rem] overflow-hidden">
                <div className="absolute inset-0 rounded-lg lg:rounded-r-[2rem] border border-white/20"></div>
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                  <div className="relative w-full grow">
                    <div className="overflow-hidden rounded-tl-xl ">
                      <img
                        src="https://cdn.pmlsound.com/paradigm/NHS/Terms.webp"
                        alt="tnc"
                      />
                    </div>
                    <ul className=" list-disc list-inside px-10 text-sm/6 text-gray-800 py-10 font-medium">
                      <li>
                        <b>Eligibility:</b> This promotion is exclusively
                        available to attendees of the National Home Show who
                        have scanned the QR code at our booth.
                      </li>
                      <li>
                        <b>Promo Code:</b> To redeem the 20% discount, customers
                        must use the promo code at participating dealers during
                        the promo period.
                      </li>
                      <li>
                        <b>Promo Period:</b> This offer is valid from March 7,
                        2025, through March 21, 2025.
                      </li>
                      <li>
                        <b>Discount Validity:</b> The discount applies only to
                        the Garden Oasis Essentials Outdoor System, Stylus
                        Series, and Rock Monitor SM. Discounts cannot be applied
                        to other products or services.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Nhs2024;
