import React, { useEffect, useContext } from "react";
import { InputField, CheckboxField, SelectField, Radio } from "./FormFields";
import {
  Formik,
  ErrorMessage,
  Form,
  useFormikContext,
  useFormik,
} from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/solid";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import { validationSchema } from "./FormModel/validationSchema";
import formInitialValues from "./FormModel/formInitialValues";
import DatePickerField from "./FormFields/DatePickerField";
import { MultiSelect } from "react-multi-select-component";
import { Alert } from "./Alert";
import axios from "axios";
import SelectFields from "./FormFields/SelectFields";
import { appContext } from "../App";
const SITE_KEY_PARADIGM = "6Ldcun0qAAAAAMO1Oq2byDo6ngclcrgR39mw_aiN";
const SITE_KEY_ANTHEM = "6Lfgun0qAAAAAHoHM3xSrcXhytixBtQqt11kLrQc";

export const countries = [
  { label: "Canada", value: "canada" },
  { label: "USA", value: "usa" },
];

export const canadianStates = [
  {
    value: "ON",
    label: "Ontario",
  },
  {
    value: "QC",
    label: "Quebec",
  },
  {
    value: "NS",
    label: "Nova Scotia",
  },
  {
    value: "NB",
    label: "New Brunswick",
  },
  {
    value: "MB",
    label: "Manitoba",
  },
  {
    value: "BC",
    label: "British Columbia",
  },

  {
    value: "PE",
    label: "Prince Edward Island",
  },
  {
    value: "SK",
    label: "Saskatchewan",
  },
  {
    value: "AB",
    label: "Alberta",
  },
  {
    value: "NL",
    label: "Newfoundland and Labrador",
  },
];

export const americanStates = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const ownershipType = [
  {
    value: "proprietorship",
    label: "Proprietorship",
  },
  {
    value: "partnership",
    label: "Partnership",
  },
  {
    value: "incorporated",
    label: "Incorporated",
  },
  {
    value: "llc",
    label: "LLC",
  },
];

const annualSalesOptions = [
  { label: "25,000", value: "25000" },
  { label: "25,000 - 50,000", value: "25000-50000" },
  { label: "50,000 - 75,000", value: "50000-75000" },
  { label: "75,000 - 100,000", value: "75000-100000" },
  { label: "100,000 - 200,000", value: "100000-200000" },
  { label: "200,000 - 300,000", value: "200000-300000" },
  { label: "300,000 - 400,000", value: "300000-400000" },
  { label: "400,000 - 500,000", value: "400000-500000" },
  { label: "500,000+", value: "500000+" },
];

const annualCommitmentOptions = [
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
  { label: "30%", value: "30" },
  { label: "40%", value: "40" },
  { label: "50%", value: "50" },
];

const otherAuthSpeakerOptions = [
  { label: "Monitor Audio", value: "monitor_audio" },
  { label: "Klipsch", value: "klipsch" },
  { label: "Kef", value: "kef" },
  { label: "Dynaudio", value: "dynaudio" },
  { label: "B&W", value: "B&W" },
  { label: "SVS", value: "svs" },
  { label: "Orgin", value: "orgin" },
  { label: "Sonance", value: "sonance" },
  { label: "Leon", value: "leon" },
  { label: "Wisdom", value: "wisdom" },
  { label: "MartinLogan", value: "martin_logan" },
  { label: "Golden Ear", value: "golden_ear" },
  { label: "Wilson Audio", value: "wilson_audio" },
  { label: "Linn", value: "linn" },
  { label: "PSB", value: "psb" },
  { label: "Focal", value: "focal" },
  { label: "Dali", value: "dali" },
  { label: "Sonus Faber", value: "sonus_faber" },
  { label: "JL", value: "jl" },
  { label: "Revel", value: "revel" },
  { label: "Definitive Technologies", value: "definitive_technologies" },
  { label: "Pold Audio", value: "pold_audio" },
  { label: "B&O", value: "B&O" },
  { label: "James", value: "james" },
  // { label: "Other", value: "other" },
];

const otherAuthElectronicsOptions = [
  { label: "Yamaha", value: "yamaha" },
  { label: "Denon", value: "denon" },
  { label: "Marantz", value: "marantz" },
  { label: "Audio Control", value: "audio_control" },
  { label: "Arcam", value: "arcam" },
  { label: "Onkyo", value: "onkyo" },
  { label: "Sony", value: "sony" },
  { label: "Integra", value: "integra" },
  { label: "Pioneer", value: "pioneer" },
  { label: "StormAudio", value: "stormAudio" },
  { label: "Trinnov", value: "trinnov" },
  { label: "McIntosh", value: "mcIntosh" },
  { label: "Bryston", value: "bryston" },
  { label: "Parasound", value: "parasound" },
  { label: "Peachtree", value: "peachtree" },
  { label: "NAD", value: "NAD" },
  { label: "Linn", value: "linn" },
  { label: "SimAudio/Moon", value: "SimAudio/Moon" },
  { label: "Aurender", value: "aurender" },
  { label: "Cambridge", value: "cambridge" },
  { label: "Audio Research", value: "audio_research" },
  { label: "Technics", value: "technics" },
  // { label: "Other", value: "other" },
];

const controlAutomationOptions = [
  { label: "RTI", value: "RTI" },
  { label: "URC", value: "URC" },
  { label: "Control4", value: "control4" },
  { label: "Crestron", value: "crestron" },
  { label: "Elan", value: "elan" },
  { label: "Savant", value: "savant" },
  { label: "josh.ai", value: "josh.ai" },
  // { label: "Other", value: "other" },
];

const { formId, formField } = checkoutFormModel;

const FormElement = () => {
  const {
    title_of_principle,
    first_name_of_principle,
    last_name_of_principle,
    date_business_commenced,
    company_name,
    company_phone_number,
    company_email,
    company_website_url,
    total_annual_sales,
    number_of_employees,
    ownership_type,
    company_unit,
    company_street_address,
    company_city,
    company_state,
    company_postal_code,
    company_country,
    percentage_annual_sales_audio,
    other_authorized_speaker_brands,
    other_authorized_electronics_brands,
    control_automation_brands,
  } = formField;
  const [alertData, setAlertData] = React.useState({});
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { country } = useContext(appContext);

  const pathname = window.location.href.includes("paradigm")
    ? "paradigm"
    : window.location.href.includes("anthem")
    ? "anthem"
    : "martinlogan";

  const SITE_KEY = window.location.href.includes("paradigm")
    ? SITE_KEY_PARADIGM
    : SITE_KEY_ANTHEM;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const formik = useFormik({
  //   onSubmit: (values) => {},
  // });
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  const [selectedOtherAuthSpeaker, setSelectedOtherAuthSpeaker] =
    React.useState([]);

  const [selectedOtherAuthElectronics, setSelectedOtherAuthElectronics] =
    React.useState([]);

  const [selectedControlAutomation, setSelectedControlAutomation] =
    React.useState([]);

  // useEffect(() => {
  //   formik.setFieldValue(
  //     "other_authorized_speaker_brands",
  //     selectedOtherAuthSpeaker
  //   );
  // }, [selectedOtherAuthSpeaker]);

  // useEffect(() => {
  //   formik.setFieldValue(
  //     "other_authorized_electronics_brands",
  //     selectedOtherAuthElectronics
  //   );
  // }, [selectedOtherAuthElectronics]);

  // useEffect(() => {
  //   formik.setFieldValue(
  //     "control_automation_brands",
  //     selectedControlAutomation
  //   );
  // }, [selectedControlAutomation]);

  // console.log(alertData);

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    console.log(values);

    const otherAuthSpeakers = selectedOtherAuthSpeaker.map((_) => _.label);
    const otherAuthElectronics = selectedOtherAuthElectronics.map(
      (_) => _.label
    );
    const controlAutomation = selectedControlAutomation.map((_) => _.label);
    const newData = {
      ...values,
      other_authorized_electronics_brands: otherAuthElectronics.toString(),
      other_authorized_speaker_brands: otherAuthSpeakers.toString(),
      control_automation_brands: controlAutomation.toString(),
      brand: pathname,
    };

    if (
      !newData.other_authorized_electronics_brands.length ||
      !newData.other_authorized_speaker_brands.length ||
      !newData.control_automation_brands.length
    ) {
      setAlertData({
        status: "error",
        title: "Error",
        message: "All fields with an * are required. Please try again",
      });
      setDisplayAlert(true);
      window.scrollTo(0, 0);
    } else {
      setLoading(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(SITE_KEY, { action: "submit" })
          .then((token) => {
            // const formData = new FormData();
            // formData.append("token", token);

            // for (var key in newData) {
            //   formData.append(key, newData[key]);
            // }

            axios({
              method: "post",
              url: "https://apps.paradigm.com/api/public/dealer_agreement",
              // url: "http://localhost:3000/api/public/dealer_agreement",
              data: { ...newData, token: token },
              // headers: {
              //   "content-type": "multipart/form-data",
              // },
            })
              .then((res) => {
                // console.log(res);
                window.scrollTo(0, 0);
                setAlertData(res.data);
                setDisplayAlert(true);
                setSelectedOtherAuthSpeaker([]);
                setSelectedOtherAuthElectronics([]);
                setSelectedControlAutomation([]);
              })
              .catch((err) => {
                if (err?.response?.status === 422) {
                  setAlertData({
                    ...err.response.data,
                    formField,
                    type: "error",
                  });
                } else {
                  setAlertData({
                    status: "error",
                    title: "Error",
                    message: ["Something went wrong..."],
                  });
                }
                // console.log(err);
                setDisplayAlert(true);
                window.scrollTo(0, 0);
              })
              .finally(() => {
                if (!"status" in alertData === "success") {
                  actions.resetForm({ values: "" });
                }
                setLoading(false);
              });
          });
      });

      actions.resetForm({ values: "" });
    }
    // alert(JSON.stringify(values, null, 2));
  }

  function _handleSubmit(values, actions) {
    // console.log(values);

    _submitForm(values, actions);
  }

  return (
    <>
      <div>
        <img
          className="max-w-7xl mx-auto object-cover "
          src={
            pathname === "anthem"
              ? "https://cdn.pmlsound.com/anthem/anthem-letterhead.svg"
              : "https://cdn.pmlsound.com/paradigm/paradigm-letterhead.jpg"
          }
          alt="header"
        />
      </div>

      <div className="font-sans max-w-7xl mx-auto lg:px-24">
        <div className="">
          <div className="">
            <h2 className="text-3xl font-extrabold text-gray-900 mt-7 px-6 py-4">
              REQUEST AGREEMENT FORM
            </h2>
            <p className="px-6 ">
              Interested in becoming an authorized dealer for{" "}
              <span className="capitalize">{pathname}</span>? If so, please take
              a moment to fill out the form below. <br /> A member of our sales
              team in your local market will reach out to you within 5-10
              business days.
            </p>

            <div className=" ">
              <div className=" border-t border-gray-300 px-4 py-5 sm:p-6 mt-3">
                {displayAlert && (
                  <Alert
                    status={alertData.status}
                    title={alertData.title}
                    message={alertData.message}
                    // formField={alertData.formField}
                  />
                )}

                <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Request Dealer Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Become a <span className="capitalize">{pathname}</span>{" "}
                      Dealer
                    </p>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={validationSchema}
                      onSubmit={_handleSubmit}
                    >
                      {({
                        isSubmitting,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <Form id={formId} className="">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={title_of_principle.name}
                                label={title_of_principle.label}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={first_name_of_principle.name}
                                label={first_name_of_principle.label}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={last_name_of_principle.name}
                                label={last_name_of_principle.label}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <DatePickerField
                                name={date_business_commenced.name}
                                label={date_business_commenced.label}
                              />
                              {/* <SelectField
                    name={date_business_commenced.name}
                    label={date_business_commenced.label}
                    data={datesOptions}
                  /> */}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={company_name.name}
                                label={company_name.label}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="name_of_principle"
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Ownership Type*
                              </label>
                              {ownershipType.map((item) => (
                                <div
                                  key={item.value}
                                  onClick={() => {
                                    setFieldValue("ownership_type", item.value);
                                  }}
                                  className={
                                    "cursor-pointer group  relative  flex items-center space-x-3 "
                                  }
                                >
                                  <Radio
                                    name={ownership_type.name}
                                    label={ownership_type.label}
                                    value={item.value}
                                  />

                                  <div className=" flex-1 min-w-0 ">
                                    <span
                                      className="absolute inset-0"
                                      aria-hidden="true"
                                    />
                                    <p className="font-work-sans text-sm font-medium text-gray-800 pt-1 ">
                                      {item.label}
                                    </p>
                                  </div>
                                </div>
                              ))}

                              {errors.ownership_type &&
                              !values.ownership_type ? (
                                <div className="text-red-600 text-sm mt-3">
                                  Choose one option
                                </div>
                              ) : null}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={company_phone_number.name}
                                label={company_phone_number.label}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={company_email.name}
                                label={company_email.label}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={company_website_url.name}
                                label={company_website_url.label}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              {/* <InputField
                    name={total_annual_sales.name}
                    label={total_annual_sales.label}
                  /> */}
                              <SelectField
                                name={total_annual_sales.name}
                                label={total_annual_sales.label}
                                data={annualSalesOptions}
                                margin={"mt-2"}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <InputField
                                name={number_of_employees.name}
                                label={number_of_employees.label}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <SelectField
                                name={percentage_annual_sales_audio.name}
                                label={percentage_annual_sales_audio.label}
                                data={annualCommitmentOptions}
                                margin={"mt-2"}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Other Authorized Speaker or Subwoofer Brands*
                              </label>
                              <MultiSelect
                                options={otherAuthSpeakerOptions}
                                value={selectedOtherAuthSpeaker}
                                onChange={setSelectedOtherAuthSpeaker}
                                labelledBy="Select"
                                isCreatable={true}
                              />
                              <p className="text-xs leading-4 mt-2 text-gray-500">
                                If other authorized speaker or subwoofer brands
                                are not listed, start typing the name and hit
                                create.
                              </p>
                              {/* {!values.other_authorized_speaker_brands.length &&
                            "other_authorized_speaker_brands" in errors ? (
                              <div className="text-red-600 text-sm mt-3">
                                Choose atleast one option
                              </div>
                            ) : null} */}
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Other Authorized Electronics Brands*
                              </label>
                              <MultiSelect
                                options={otherAuthElectronicsOptions}
                                value={selectedOtherAuthElectronics}
                                onChange={setSelectedOtherAuthElectronics}
                                labelledBy="Select"
                                isCreatable={true}
                              />
                              <p className="text-xs leading-4 mt-2 text-gray-500">
                                If other authorized electronics brands are not
                                listed, start typing the name and hit create.
                              </p>
                              {/* {!values.other_authorized_electronics_brands
                              .length &&
                            "other_authorized_electronics_brands" in errors ? (
                              <div className="text-red-600 text-sm mt-3">
                                Choose atleast one option
                              </div>
                            ) : null} */}
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Control/Automation Brands*
                              </label>
                              <MultiSelect
                                options={controlAutomationOptions}
                                value={selectedControlAutomation}
                                onChange={setSelectedControlAutomation}
                                labelledBy="Select"
                                isCreatable={true}
                              />
                              <p className="text-xs leading-4 mt-2 text-gray-500">
                                If Control/Automation Brands are not listed,
                                start typing the name and hit create.
                              </p>
                              {/* {!values.control_automation_brands.length &&
                            "control_automation_brands" in errors ? (
                              <div className="text-red-600 text-sm mt-3">
                                Choose atleast one option
                              </div>
                            ) : null} */}
                            </div>
                          </div>

                          <div className="mt-10 mb-10">
                            <p className="font-bold text-xl">
                              Address Information
                            </p>
                          </div>
                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className=" sm:col-span-2">
                              <InputField
                                name={company_street_address.name}
                                label={company_street_address.label}
                              />
                            </div>
                            <div className=" sm:col-span-2">
                              <InputField
                                name={company_unit.name}
                                label={company_unit.label}
                              />
                            </div>
                            <div className=" sm:col-span-2">
                              <SelectField
                                name={company_country.name}
                                label={company_country.label}
                                data={countries}
                              />
                            </div>
                            <div className=" sm:col-span-2">
                              <SelectField
                                name={company_state.name}
                                label={company_state.label}
                                data={
                                  values.company_country === "canada"
                                    ? canadianStates
                                    : americanStates
                                }
                              />
                            </div>
                            <div className=" sm:col-span-2">
                              <InputField
                                name={company_city.name}
                                label={company_city.label}
                              />
                            </div>

                            <div className=" sm:col-span-2">
                              <InputField
                                name={company_postal_code.name}
                                label={company_postal_code.label}
                              />
                            </div>
                          </div>

                          <div className="mt-3  px-0 py-3  text-right ">
                            <button
                              type="submit"
                              className="mb-6 bg-gradient-to-t from-blue-800  to-blue-500 focus:ring-indigo-500 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                              {loading && (
                                <svg
                                  role="status"
                                  class="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-400 fill-gray-50"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              )}
                              {loading ? "Processing" : "Place Request"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          className="max-w-7xl mx-auto object-cover "
          src={
            pathname === "anthem"
              ? "https://cdn.pmlsound.com/anthem/anthem-footer.svg"
              : "https://cdn.pmlsound.com/paradigm/paradigm-anthem-footer.svg"
          }
          alt="footer"
        />
      </div>
    </>
  );
};

export default FormElement;
